export default class GradingSkills {
	constructor(api) {
		this.api = api;
	}

	getFromId = (id) => this.api.processRequest("GET", `/grading_skills/${id}`);

	getAvailableInstances = () =>
		this.api.processRequest("GET", "/grading_skills/available_instances");

	getEvaluations(year, scale_id, module, offset = 0, limit = 50) {
		const params = {
			scholar_year: year,
			bareme_id: scale_id,
			unit_code: module,
			offset,
			limit,
		};
		return this.api.processRequest("GET", "/evaluations", { params });
	}

	getByIds(ids) {
		return this.api.processRequest("GET", "/grading_skills/by_ids", {
			params: { ids: ids.join(",") },
		});
	}
}
